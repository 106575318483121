<template>
  <div class="flex-v flex-align-center w100">
    <div
      class="flex-v w100 flex-align-center ignore-news-header"
      
    >
    <!-- :style="headerTop" -->
      <div class="ignore-fz32 bold ignore-mt120 z0">最新动态</div>
      <div class="ignore-fz16 ignore-mt8 uppercase c-cac z0">Our news</div>
      <div class="z0 ignore-color-line ignore-mt8"></div>
      <div class="flex ignore-news-tab flex-nowrap">
        <div
          class="flex-v flex-1 flex-align-center ignore-type-item pointer"
          v-for="(item, index) in newsTypeList"
          :key="index"
          @mouseenter="item.mouse = true"
          @mouseleave="item.mouse = false"
          @click="getListByType(item)"
        >
          <img
            v-lazy="activeBg"
            class="ignore-active-bg"
            :class="
              activeNewsType.channelId == item.channelId
                ? 'active-bg-show'
                : item.mouse == true
                ? 'active-bg-show'
                : item.mouse == false
                ? 'active-bg-none'
                : 'active-bg-no'
            "
          />

          <div class="ignore-fz14 ignore-mt30">{{ item.channelName }}</div>
          <div class="ignore-fz32 bold">{{ item.cChildNum }}</div>
        </div>
      </div>
    </div>
    <div
      class="ignore-news-body flex-v flex-align-center"
      ref="newsHeader"
      
    >
    <!-- :style="bodyTop" -->
      <div class="flex ignore-news">
        <div class="ignore-detail-list flex-v">
          <el-input placeholder="输入关键字搜索" v-model="keyword">
            <i
              slot="suffix"
              class="el-input__icon el-icon-search pointer iconsearch"
              style="
                
              "
              @click="getSearchList"
            ></i>
          </el-input>
          <div class="ignore-fz16 ignore-mt26 ignore-mb10">为你推荐</div>
          <div class="flex-v w100">
            <div
              class="ignore-detail-item flex flex-align-center pointer"
              v-for="(item, index) in recommandList"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="ignore-fz16 max-line1">{{ item.articleTitle }}</div>
            </div>
          </div>
        </div>
        <div class="flex-v flex-align-center" v-if="showList">
          <div class="ignore-list-box flex-v">
            <div
              class="ignore-list-item-box flex flex-align-center pointer"
              v-for="(item, index) in newsList"
              :key="index"
            >
              <router-link
                :to="{
                  name: 'newsCenter',
                  query: {
                    typeId: activeNewsType.channelId,
                    articleId: item.articleId,
                    pageIndex: pageIndex,
                    name: item.articleTitle,
                  },
                }"
                target="_blank"
                class="w100"
              >
                <div class="ignore-list-item flex flex-align-center pointer">
                  <img
                    class="ignore-list-icon"
                    :src="
                      item.articleCover
                        ? item.coverUrl + item.coverPath + item.articleCover
                        : newsDefault
                    "
                  />
                  <div class="flex-v flex-1">
                    <div class="ignore-fz18 max-line1">
                      {{ item.articleTitle }}
                    </div>
                    <div
                      class="ignore-fz14 c-999 max-line2 ignore-mt10 ignore-ml1-fz16"
                    >
                      {{ item.articleIntro }}
                    </div>
                    <div class="flex flex-align-center ignore-mt10">
                      <span class="c-999 bold ignore-fz16 ignore-mr20">{{
                        item.ptime
                      }}</span>
                      <div class="flex flex-align-center">
                        <img class="ignore-watch-icon" v-lazy="watchPic" />
                        <span class="ignore-fz12 c-999">{{ item.pv }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <el-pagination
            background="white"
            layout="prev, pager, next"
            :total="totalRecord"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            class="ignore-mt40"
          >
          </el-pagination>
        </div>
        <div class="ignore-detail-box flex-v" v-else>
          <div class="ignore-fz14 c-39e pointer" @click="backList">
            &lt; 返回上一级
          </div>
          <div class="ignore-mt40 ignore-fz24 bold">
            {{ activeNews.articleTitle }}
          </div>
          <div class="flex flex-align-center ignore-mt14">
            <span class="c-999 bold ignore-fz16 ignore-mr20">{{
              activeNews.ptime
            }}</span>
            <div class="flex flex-align-center">
              <img class="ignore-watch-icon" v-lazy="watchPic" />
              <span class="ignore-fz12 c-999">{{ activeNews.pv }}</span>
            </div>
          </div>
          <div class="ignore-grey-line"></div>
          <div
            v-html="activeNews.articleContent"
            class="ignore-mt30 ignore-html"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
window.addEventListener("beforeunload", function () {
  window.scrollTo(0, 0);
});
import activeBg from "@/assets/home/active-bg.png";
import watchPic from "@/assets/home/watch.png";
import newsDefault from "@/assets/news/icon.jpg";

import {
  getNewsType,
  getNewsList,
  getNewsRecommendList,
  getNewsDetail,
} from "@/api/news";
export default {
  name: "newsCenter",
  data() {
    return {
      activeBg,
      watchPic,
      newsDefault,
      newsTypeList: [],
      activeNewsType: {},
      headerTop: {
        marginTop: "0px",
        position: "relative",
      },
      bodyTop: {
        paddingTop: "0px",
      },
      newsList: [],
      recommandList: [],
      showList: true,
      activeNews: {},
      keyword: "",
      pageIndex: 1,
      pageSize: 10,
      totalRecord: 0,
    };
  },
  methods: {
    newsScroll() {
      if (this.$refs.newsHeader.getBoundingClientRect().top == 70) {
        this.headerTop.position = "relative";
        this.headerTop.marginTop = "0px";
        this.bodyTop.paddingTop = "0px";
      } else if (
        this.$refs.newsHeader.getBoundingClientRect().top > -150 &&
        this.headerTop.position == "fixed"
      ) {
        this.headerTop.position = "relative";
        this.headerTop.marginTop = "0px";
        this.bodyTop.paddingTop = "0px";
      } else if (this.$refs.newsHeader.getBoundingClientRect().top < 220) {
        this.headerTop.marginTop = "-220px";
        this.headerTop.position = "fixed";
        this.bodyTop.paddingTop = "430px";
      } else {
        this.headerTop.position = "relative";
        this.headerTop.marginTop = "0px";
        this.bodyTop.paddingTop = "0px";
      }
    },
    newsScroll2() {
      if (this.$refs.newsHeader.getBoundingClientRect().top == 70) {
        // this.headerTop.position = "relative";
        // this.headerTop.marginTop = "0px";
        // this.bodyTop.paddingTop = "0px";
      } else if (
        this.$refs.newsHeader.getBoundingClientRect().top > -156 &&
        this.headerTop.position == "fixed"
      ) {
        this.headerTop.position = "relative";
        this.headerTop.marginTop = "0px";
        this.bodyTop.paddingTop = "0px";
      } else if (this.$refs.newsHeader.getBoundingClientRect().top < 150) {
        this.headerTop.marginTop = "-200px";
        this.headerTop.position = "fixed";
        this.bodyTop.paddingTop = "360px";
      } else {
        this.headerTop.position = "relative";
        this.headerTop.marginTop = "0px";
        this.bodyTop.paddingTop = "0px";
      }
    },
    toDetail(item) {
      let toTaskLog = this.$router.resolve({
        path: "/newsCenter",
        query: {
          typeId: this.activeNewsType.channelId,
          articleId: item.articleId,
          name: item.articleTitle,
          pageIndex: this.pageIndex,
        },
      });
      window.open(toTaskLog.href, "_blank");
      // this.$router.push({
        
      // });
    },
    getDetail(item) {
      // this.$router.push({
      //   query: {
      //     name: item.articleTitle,
      //   },
      // });
      getNewsDetail(item.articleId).then((res) => {
        // res.ptime = this.tarnslateDate(res.ptime);
        this.activeNews = res;
      });

      this.showList = false;
      this.initScroll();
    },
    backList() {
      this.activeNews = {};
      this.showList = true;
      this.initScroll();
      this.$router.push({ query: {} });
    },
    initScroll() {
      if (this.headerTop.position == "fixed") {
        window.scrollTo(0, 250);
      }
    },
    getNewsType() {
      getNewsType().then((res) => {
        let list = res;
        res.forEach((item) => {
          item.mouse = -1;
        });
        this.newsTypeList = list;
        if (this.$route.query.typeId) {
          let typeItem = this.newsTypeList.find(
            (item) => item.channelId == this.$route.query.typeId
          );
          this.getNewsList(typeItem);
        } else {
          this.getNewsList(list[0]);
        }
      });
    },
    getSearchList() {
      this.$router.push({ query: {} });
      this.getNewsList();
    },
    getNewsList(item) {
      this.activeNews = {};
      this.showList = true;
      this.initScroll();
      if (item && item.channelId) {
        this.activeNewsType = item;
        this.getNewsRecommendList(this.activeNewsType.channelId);
        this.keyword = "";
        if (this.$route.query.pageIndex && this.$route.query.pageIndex > 1) {
          this.pageIndex = this.$route.query.pageIndex;
        } else {
          this.pageIndex = 1;
        }
      }
      let param = {
        KeyWords: this.keyword,
        ChannelId: this.activeNewsType.channelId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      getNewsList(param).then((res) => {
        // res.objList.forEach((item) => {
        //   item.ptime = this.tarnslateDate(item.ptime);
        // });
        this.newsList = res.objList;
        this.totalRecord = res.totalRecord;
        if (this.$route.query.articleId) {
          let newsItem = this.newsList.find(
            (item) => item.articleId == this.$route.query.articleId
          );
          if (newsItem && newsItem.articleId) {
            this.getDetail(newsItem);
          }
        }
      });
    },
    tarnslateDate(date) {
      let nowdate = new Date(date).toLocaleDateString();
      nowdate = nowdate.substring(2);
      return nowdate;
    },
    handleCurrentChange(page) {
      this.pageIndex = page;
      this.getNewsList();
    },
    getNewsRecommendList(channelId) {
      getNewsRecommendList(channelId).then((res) => {
        this.recommandList = res.objList;
      });
    },
    getListByType(item) {
      // this.backList();
      // this.getNewsList(item);
      this.$router.push({
        path: "/newsCenter",
        query: {
          typeId: item.channelId,
          name: item.channelName,
        },
      });
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.newsScroll);
  },
  mounted() {
    if (window.screen.width <= 1366) {
      window.addEventListener("scroll", this.newsScroll2);
    } else {
      window.addEventListener("scroll", this.newsScroll);
    }
    this.getNewsType();
  },
  activated() {
    this.newsTypeList.forEach((item) => {
      item.mouse = -1;
    });
    this.newsList.forEach((item) => {
      item.active = -1;
    });
    this.showList = true;
    this.activeNews = {};
    window.scrollTo(0, 0);
    this.headerTop.position = "relative";
    this.headerTop.marginTop = "0px";
    if (this.newsTypeList.length > 0 && this.$route.query.typeId) {
      let typeItem = this.newsTypeList.find(
        (item) => item.channelId == this.$route.query.typeId
      );
      this.getNewsList(typeItem);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  border-radius: 50px;
}
.iconsearch{
  color: #39e274;
  font-size: 16px;
  font-weight: bold;
  margin-right: 6px;
  width: 25px;
  height: 40px;
}
.ignore-color-line {
  width: 50px;
  height: 3px;
  background: linear-gradient(-90deg, #34b5ff, #39e274);
  border-radius: 2px;
}
.ignore-news-header {
  z-index: 2;
  width: 100%;
  height: 390px;
  background: url("../assets/news/bg.jpg") no-repeat;
  background-size: 100% 320px;
  background-color: #f1f1f1;
  -webkit-transform: translateZ(0);
  left: 0;

  .ignore-news-tab {
    margin-top: 68px;
    width: 1500px;
    height: 100px;
    background: #fff;

    border-radius: 6px;
    .ignore-type-item {
      height: 100px;
      position: relative;
      .ignore-active-bg {
        position: absolute;
        top: 0;
        height: 100px;
        margin: 0 auto; /*水平居中*/
        left: 0; /*此处不能省略，且为0*/
        right: 0; /*此处不能省略，且为0*/
      }
    }
  }
}
.ignore-news-body {
  width: 100%;
  min-height: 500px;
  background: #f1f1f1;
  box-sizing: content-box;
  padding-bottom: 40px;
  .ignore-news {
    width: 1500px;
  }
}
.ignore-detail-list {
  width: 500px;
  max-height: 750px;
  background: #ffffff;
  border-radius: 6px;
  margin-right: 20px;
  padding: 30px;
  overflow: auto;
  padding-bottom: 0;
  .ignore-detail-item {
    width: 100%;
    padding: 14px 0;
    background: #ffffff;
    border-bottom: 1px solid #e2e2e2;
  }
  .ignore-detail-item:last-child {
    border-bottom: none;
  }
  .ignore-detail-active {
    width: 4px;
    height: 102px;
    background: #39e274;
  }
}
.ignore-detail-box {
  width: 980px;
  min-height: 320px;
  background: #ffffff;
  border-radius: 6px;
  padding: 30px;
  .ignore-detail-icon {
    height: 400px;
    margin: 58px auto;
  }
  .ignore-html {
    width: 100%;
  }
  .ignore-html >>> img {
    max-width: 100%;
    margin: 0 auto;
  }
  .ignore-html >>> p {
    line-height: 1.5em;
    font-size: 16px;
  }
  .ignore-grey-line {
    height: 1px;
    width: 100%;
    background: #e2e2e2;
    margin-top: 30px;
  }
}
.ignore-list-box {
  padding: 20px 30px;
  width: 980px;
  background: #ffffff;
  border-radius: 6px;
  min-height: 300px;
  .ignore-list {
    max-height: 1079px;
    overflow: auto;
  }
  .ignore-list-item-box {
    width: 100%;
    height: 140px;
    background: #ffffff;
    border-bottom: 1px solid #e2e2e2;
  }
  .ignore-list-item {
    width: 100%;
    height: 139px;
    background: #ffffff;
    .ignore-list-icon {
      width: 160px;
      height: 101px;
      margin-right: 30px;
    }
  }
  .ignore-list-item-box:last-child {
    border-bottom: none;
  }
}
.ignore-watch-icon {
  width: 20px;
  height: 14px;
  margin-right: 8px;
}
.ignore-more-btn-box {
  width: 150px;
  height: 50px;
  .ignore-more-btn {
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    position: absolute;
    left: 0;
    top: 0;
  }
  .ignore-more-btn-active {
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background: linear-gradient(
      -45deg,
      rgba(52, 181, 255, 0.83),
      rgba(57, 226, 116, 0.83)
    );
    box-shadow: 0px 18px 25px 0px rgba(57, 226, 116, 0.33);
    border: none;
    position: absolute;
    left: 0;
    top: 0;
  }
  .more-hidden {
    opacity: 0;
  }
}
.active-bg-show {
  width: 100%;
  animation: moveShow 0.7s;
}
.active-bg-none {
  width: 0px;
  animation: moveNone 0.7s;
}
.active-bg-no {
  width: 0px;
}
@keyframes moveShow {
  0% {
    width: 10px;
  }
  100% {
    width: 100%;
  }
}
@keyframes moveNone {
  0% {
    width: 100%;
  }
  100% {
    width: 0px;
  }
}
</style>
<!-- <style scoped>
@media (max-width: 1366px) {
  .ignore-news-header {
    height: 310px !important;
    background-size: 100% 260px !important;
  }
  .ignore-news-tab {
    margin-top: 48px !important;
    width: 900px !important;
    height: 70px !important;
  }
  .ignore-type-item {
    height: 70px !important;
  }
  .ignore-active-bg {
    height: 70px !important;
  }
  .ignore-news-header .ignore-mt120 {
    margin-top: 100px !important;
  }
  .ignore-news-header .ignore-mt30 {
    margin-top: 12px !important;
  }
  .ignore-news-header .ignore-fz32 {
    font-size: 26px !important;
  }
  .ignore-news {
    width: 900px !important;
  }
  .ignore-detail-list {
    width: 230px !important;
    max-height: 440px !important;
    padding: 20px 12px 0px 12px !important;
    margin-right: 16px !important;
    height: 350px !important;
  }
  .ignore-detail-list .ignore-mb10 {
    margin-bottom: 0px !important;
  }
  .ignore-detail-list .ignore-mt26 {
    margin-top: 18px !important;
  }
  .ignore-detail-list .ignore-fz16 {
    font-size: 14px !important;
  }
  .ignore-detail-list .ignore-fz20 {
    font-size: 16px !important;
  }
  .ignore-detail-list .ignore-mt20 {
    margin-top: 12px !important;
  }
  .ignore-list-box {
    width: 654px !important;
    padding: 12px 20px !important;
  }
  .ignore-list-box .ignore-fz24 {
    font-size: 20px !important;
  }
  .ignore-list-item-box {
    height: 100px !important;
  }
  .ignore-list-item {
    height: 99px !important;
  }
  .ignore-list-icon {
    width: 140px !important;
    height: 80px !important;
    margin-right: 16px !important;
  }
  .ignore-list-item .ignore-fz16 {
    font-size: 14px !important;
  }
  .ignore-list-item .ignore-fz18 {
    font-size: 16px !important;
  }
  .ignore-list-box .ignore-mt40 {
    margin-top: 24px;
  }
  .ignore-detail-box {
    width: 654px !important;
  }
  .ignore-detail-box .ignore-mt40 {
    margin-top: 26px !important;
  }
  .ignore-detail-box .ignore-fz24 {
    font-size: 20px !important;
  }
  .ignore-detail-box .ignore-mt14 {
    margin-top: 6px !important;
  }
  .ignore-detail-box .ignore-fz16 {
    font-size: 14px !important;
  }
  .ignore-grey-line {
    margin-top: 14px !important;
  }
  .ignore-detail-box .ignore-mt30 {
    margin-top: 20px !important;
  }
  .ignore-html >>> p {
    line-height: 1.2em;
    font-size: 14px !important;
  }
  .ignore-html >>> span {
    line-height: 1.2em;
    font-size: 14px !important;
  }
  .ignore-grey-line {
    margin-top: 30px;
  }
}
</style> -->