var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-v flex-align-center w100"},[_c('div',{staticClass:"flex-v w100 flex-align-center ignore-news-header"},[_c('div',{staticClass:"ignore-fz32 bold ignore-mt120 z0"},[_vm._v("最新动态")]),_c('div',{staticClass:"ignore-fz16 ignore-mt8 uppercase c-cac z0"},[_vm._v("Our news")]),_c('div',{staticClass:"z0 ignore-color-line ignore-mt8"}),_c('div',{staticClass:"flex ignore-news-tab flex-nowrap"},_vm._l((_vm.newsTypeList),function(item,index){return _c('div',{key:index,staticClass:"flex-v flex-1 flex-align-center ignore-type-item pointer",on:{"mouseenter":function($event){item.mouse = true},"mouseleave":function($event){item.mouse = false},"click":function($event){return _vm.getListByType(item)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.activeBg),expression:"activeBg"}],staticClass:"ignore-active-bg",class:_vm.activeNewsType.channelId == item.channelId
              ? 'active-bg-show'
              : item.mouse == true
              ? 'active-bg-show'
              : item.mouse == false
              ? 'active-bg-none'
              : 'active-bg-no'}),_c('div',{staticClass:"ignore-fz14 ignore-mt30"},[_vm._v(_vm._s(item.channelName))]),_c('div',{staticClass:"ignore-fz32 bold"},[_vm._v(_vm._s(item.cChildNum))])])}),0)]),_c('div',{ref:"newsHeader",staticClass:"ignore-news-body flex-v flex-align-center"},[_c('div',{staticClass:"flex ignore-news"},[_c('div',{staticClass:"ignore-detail-list flex-v"},[_c('el-input',{attrs:{"placeholder":"输入关键字搜索"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}},[_c('i',{staticClass:"el-input__icon el-icon-search pointer iconsearch",staticStyle:{},attrs:{"slot":"suffix"},on:{"click":_vm.getSearchList},slot:"suffix"})]),_c('div',{staticClass:"ignore-fz16 ignore-mt26 ignore-mb10"},[_vm._v("为你推荐")]),_c('div',{staticClass:"flex-v w100"},_vm._l((_vm.recommandList),function(item,index){return _c('div',{key:index,staticClass:"ignore-detail-item flex flex-align-center pointer",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('div',{staticClass:"ignore-fz16 max-line1"},[_vm._v(_vm._s(item.articleTitle))])])}),0)],1),(_vm.showList)?_c('div',{staticClass:"flex-v flex-align-center"},[_c('div',{staticClass:"ignore-list-box flex-v"},_vm._l((_vm.newsList),function(item,index){return _c('div',{key:index,staticClass:"ignore-list-item-box flex flex-align-center pointer"},[_c('router-link',{staticClass:"w100",attrs:{"to":{
                name: 'newsCenter',
                query: {
                  typeId: _vm.activeNewsType.channelId,
                  articleId: item.articleId,
                  pageIndex: _vm.pageIndex,
                  name: item.articleTitle,
                },
              },"target":"_blank"}},[_c('div',{staticClass:"ignore-list-item flex flex-align-center pointer"},[_c('img',{staticClass:"ignore-list-icon",attrs:{"src":item.articleCover
                      ? item.coverUrl + item.coverPath + item.articleCover
                      : _vm.newsDefault}}),_c('div',{staticClass:"flex-v flex-1"},[_c('div',{staticClass:"ignore-fz18 max-line1"},[_vm._v(" "+_vm._s(item.articleTitle)+" ")]),_c('div',{staticClass:"ignore-fz14 c-999 max-line2 ignore-mt10 ignore-ml1-fz16"},[_vm._v(" "+_vm._s(item.articleIntro)+" ")]),_c('div',{staticClass:"flex flex-align-center ignore-mt10"},[_c('span',{staticClass:"c-999 bold ignore-fz16 ignore-mr20"},[_vm._v(_vm._s(item.ptime))]),_c('div',{staticClass:"flex flex-align-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.watchPic),expression:"watchPic"}],staticClass:"ignore-watch-icon"}),_c('span',{staticClass:"ignore-fz12 c-999"},[_vm._v(_vm._s(item.pv))])])])])])])],1)}),0),_c('el-pagination',{staticClass:"ignore-mt40",attrs:{"background":"white","layout":"prev, pager, next","total":_vm.totalRecord,"current-page":_vm.pageIndex},on:{"current-change":_vm.handleCurrentChange}})],1):_c('div',{staticClass:"ignore-detail-box flex-v"},[_c('div',{staticClass:"ignore-fz14 c-39e pointer",on:{"click":_vm.backList}},[_vm._v(" < 返回上一级 ")]),_c('div',{staticClass:"ignore-mt40 ignore-fz24 bold"},[_vm._v(" "+_vm._s(_vm.activeNews.articleTitle)+" ")]),_c('div',{staticClass:"flex flex-align-center ignore-mt14"},[_c('span',{staticClass:"c-999 bold ignore-fz16 ignore-mr20"},[_vm._v(_vm._s(_vm.activeNews.ptime))]),_c('div',{staticClass:"flex flex-align-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.watchPic),expression:"watchPic"}],staticClass:"ignore-watch-icon"}),_c('span',{staticClass:"ignore-fz12 c-999"},[_vm._v(_vm._s(_vm.activeNews.pv))])])]),_c('div',{staticClass:"ignore-grey-line"}),_c('div',{staticClass:"ignore-mt30 ignore-html",domProps:{"innerHTML":_vm._s(_vm.activeNews.articleContent)}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }