import http from '../common/http';

export function getNewsType() { // 获取新闻类型列表
    let url = `/api/News`;
    return http.get(url).then((data) => {
        return data;
    });
}
export function getNewsList(param) { // 获取新闻列表
    let url = `/api/News/GetArticleList?KeyWords=${param.KeyWords}&ChannelId=${param.ChannelId}&pageIndex=${param.pageIndex}&pageSize=${param.pageSize}`;
    return http.get(url).then((data) => {
        return data;
    });
}
export function getNewsRecommendList(ChannelId) { // 获取推荐新闻列表
    let url = `/api/News/GetArticleRecommended?ChannelId=${ChannelId}`;
    return http.get(url).then((data) => {
        return data;
    });
}
export function getNewsDetail(ProId) { // 获取新闻详情
    let url = `/api/News/GetArticleDetail/ArticleId=${ProId}`;
    return http.get(url).then((data) => {
        return data;
    });
}